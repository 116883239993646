import { useMemo } from 'react';
import { Button } from '@mui/material';
import { useUserPermissions } from 'src/use-cases/user/useUserPermissions';
import IconExport from 'src/assets/images/icons/export.svg';
import { featureFlags } from 'src/config/featureFlags';
import { useUploadSelector } from './useUploadSelector';

export const UploadeButtonAction = () => {
  const { data } = useUserPermissions();
  const { isShowUpload, onSetupUpload } = useUploadSelector();

  const canUpload: boolean = useMemo(() => {
    if (data?.data && data?.data?.length > 0 && data?.data?.find((item) => item === 'CAN_UPLOAD_INVOICE_FILE')) {
      return true;
    }
    if (featureFlags.UCT && !featureFlags.UCT_PROCUREMENT) {
      return true;
    }
    return false;
  }, [data]);

  return (
    <div className='pr-24 flex items-center gap-16'>
      {canUpload && (
        <Button
          variant='contained'
          onClick={() => onSetupUpload()}
          disabled={isShowUpload}
          sx={{
            '&.Mui-disabled': {
              backgroundColor: '#BFBFBF',
              color: '#FFFFFF',
              cursor: 'not-allowed',
              opacity: 0.9,
            },
          }}
        >
          <div className='flex items-center gap-12'>
            <span className='capitalize'>Upload New Invoices</span>
            <img src={IconExport} alt='' />
          </div>
        </Button>
      )}
    </div>
  );
};
