import { useEffect, useMemo, useState } from 'react';
import { Box, Paper } from '@mui/material';
import { MRT_Row, MRT_RowSelectionState, MaterialReactTable } from 'material-react-table';
import { styled } from '@mui/material/styles';
import { InvoiceAnalyticsDto, InvoiceDto } from 'src/generated/services/TFinancialApi';
import { TableTab, useAnalyticsTable } from './useTable';
import MassActions from './MassActions';

export const TableWrapper = styled(Paper)({
  boxShadow: 'none',
});

const getRowId = (row: InvoiceDto) => row?.id;

export const TableMain = styled(Box)({
  '& .MuiTableCell-head': {
    fontSize: '14px',
    fontWeight: 'bold',
    backgroundColor: '#F5F6FA',
    padding: '16px 16px',
    verticalAlign: 'middle',
  },
  '& .MuiTableCell-body ': {
    padding: '16px 16px',
    fontSize: '14px',
  },
  '& .Mui-selected td': {
    backgroundColor: '#F5F6FA',
  },
  '& .MuiTableRow-root:hover td': {
    backgroundColor: '#F5F6FA',
  },
});

export const TableDetail = ({ tableDetail, chartData, page, tableTab, refetchData, onSorting }) => {
  const [columnOrder, setColumnOrder] = useState(() => tableDetail.columns.map((column) => column.accessorKey));
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [sorting, setSorting] = useState([{ id: 'INVOICE_DATE', desc: false }]);

  // Prevent unnecessary columnOrder updates
  useEffect(() => {
    const newColumnOrder = tableDetail.columns.map((column) => column.accessorKey);
    if (JSON.stringify(columnOrder) !== JSON.stringify(newColumnOrder)) {
      setColumnOrder(newColumnOrder);
    }
  }, [tableDetail.columns, columnOrder]);

  // Clear selection when tab changes
  useEffect(() => {
    setRowSelection({});
  }, [tableTab]);

  // Apply sorting
  useEffect(() => {
    const runSorting = (sortingConfig) => {
      if (sortingConfig.length > 0) {
        const config = sortingConfig[0];
        const method = config?.desc ? 'DESC' : 'ASC';
        onSorting(method, config?.id);
      } else {
        onSorting('ASC', 'INVOICE_DATE');
      }
    };
    runSorting(sorting);
  }, [sorting, onSorting]);

  // Compute selected items
  const selectedItems = useMemo(
    () =>
      Object.keys(rowSelection)
        .filter((key) => rowSelection[key])
        .map((key) => tableDetail.data?.[key])
        .filter((item) => item !== undefined),
    [rowSelection, tableDetail.data]
  );

  // Table instance
  const table = useAnalyticsTable({
    ...tableDetail,
    data: tableDetail.data ?? [],
    getRowId,
    enableSorting: true,
    manualSorting: true,
    state: {
      rowSelection,
      sorting,
    },
    onSortingChange: setSorting,
    enableRowSelection: tableTab === TableTab.INVOICES ? (row: MRT_Row<InvoiceAnalyticsDto>) => row?.original?.invoiceStatus === 'APPROVED' : false,
    onRowSelectionChange: setRowSelection,
    renderEmptyRowsFallback: () => <p className='text-center text-16 font-medium uppercase text-neutral-40 py-30'>NO DATA AVAILABLE</p>,
    enableColumnPinning: true,
    initialState: { columnPinning: { right: ['actions'] } },
  });

  return (
    <>
      <div className='w-full bg-blue_grey-5 overflow-hidden border-b border-neutral-20 rounded-bl-2xl rounded-br-2xl'>
        <div className='w-full'>
          <TableMain>
            <MaterialReactTable table={table} />
          </TableMain>
        </div>
      </div>

      <MassActions
        selectedItems={selectedItems}
        handleClearSelection={() => setRowSelection({})}
        chartData={chartData}
        page={page}
        tableTab={tableTab}
        refetchData={refetchData}
      />
    </>
  );
};
