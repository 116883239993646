import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Divider } from '@mui/material';
import { useForm } from 'react-hook-form-mui';

import { beautifyEnum } from 'src/utils/utility';
import { useCreateUserIssue } from 'src/use-cases/user/useUserIssue';
import IconTick from 'src/assets/images/icons/tick-circle-gray.svg';
import Modal from '../modal';
import { useContactSelector } from './ContactSelector';
import TransportMediumIcon from '../card/TransportMediumIcon';
import { TransportMedium } from '../card/types';
import StatusChip from '../../organisms/dashboard/StatusChip';
import { FormContainer } from '../../organisms/forms/FormContainer';
import { ModalContactForm } from './ModalContactForm';
import { IContactFormValues } from './types';

export const StyledModal = styled(Modal)({
  zIndex: 9999,

  '& .MuiDialog-paper': {
    maxWidth: '680px',
    borderRadius: '16px',
  },
  '& .MuiDialogContent-root': {
    padding: 0,
  },
});

export const ModalContact = () => {
  const { isShowContact, invoice, issue, setIsShowContact, setInvoice, setIssue } = useContactSelector();
  const { mutateAsync, isLoading } = useCreateUserIssue();

  const navigate = useNavigate();

  const [showNotification, setShowNotification] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  const formContext = useForm({
    defaultValues: {
      message: '',
    },
  });

  const onCloseModal = useCallback(() => {
    setIsShowContact(false);
    setInvoice(null);
  }, [setInvoice, setIsShowContact]);

  const onSubmit = useCallback(
    async (values: IContactFormValues) => {
      try {
        const response = await mutateAsync({
          invoiceId: invoice?.id,
          message: values?.message,
        });

        if (response?.data?.success) {
          const userIssue = response?.data?.data;
          const issueData = {
            invoiceNumber: invoice?.invoiceNumber,
            ...userIssue,
          };
          const redirectUrl = `/dashboard?provider=${invoice?.provider}&modality=${invoice?.modality}`;

          setIsShowContact(false);
          setInvoice(null);
          navigate(redirectUrl);
          setIssue(issueData);
          setShowNotification(true); // Show notification when issue is set
        }
      } catch {
        setIsShowContact(false);
        setInvoice(null);
      }
    },
    [invoice, mutateAsync, navigate, setInvoice, setIsShowContact, setIssue]
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (showNotification) {
      // Set a timeout to start the exit animation
      const timer = setTimeout(() => {
        setIsExiting(true);
      }, 4500);

      // Set another timeout to fully hide the notification
      const hideTimer = setTimeout(() => {
        setShowNotification(false);
        setIsExiting(false);
      }, 5000);

      return () => {
        clearTimeout(timer);
        clearTimeout(hideTimer);
      };
    }
  }, [showNotification]);

  return (
    <>
      <StyledModal open={isShowContact} title='Contact Tetrixx Admin' fullWidth onClose={onCloseModal}>
        <div className='relative pt-10 pb-24 px-24'>
          <div className='bg-neutral-10 rounded-10 p-16'>
            <p className='text-16 font-medium pb-16 mb-16 border-b border-neutral-20'>Problem with the Invoice data:</p>

            {invoice && (
              <>
                <div className='flex items-center gap-10'>
                  <div className='flex items-center justify-center'>
                    <TransportMediumIcon medium={invoice?.modality as TransportMedium} iconColor='#9F9F9F' size='md' />
                  </div>
                  <h2 className='text-16 font-bold text-blue_grey-80'>{beautifyEnum(invoice?.provider)?.toUpperCase()}</h2>
                  <div className='w-4 h-4 rounded-full bg-[#D9D9D9]' />
                  <div className='uppercase text-neutral-60 text-14'>{invoice?.modality}</div>
                </div>

                <div className='flex items-center gap-16 mt-6'>
                  <p className='flex items-center gap-4 text-14'>
                    Invoice No.
                    <strong className='text-16 font-bold text-primary'>{invoice?.invoiceNumber}</strong>
                  </p>
                  <Divider className='bg-neutral-20' orientation='vertical' variant='middle' flexItem />
                  <p className='text-16 font-bold text-primary'>{invoice?.mblHbl}</p>
                  <Divider className='bg-neutral-20' orientation='vertical' variant='middle' flexItem />
                  <StatusChip status={invoice?.status} size='small' />
                </div>
              </>
            )}
          </div>

          <FormContainer context={formContext} onSuccess={onSubmit}>
            <ModalContactForm isLoading={isLoading} />
          </FormContainer>
        </div>
      </StyledModal>

      {showNotification && issue && (
        <div
          className={`fixed bottom-16 left-16 z-20 px-16 py-10 rounded-6 bg-[#202020] text-white flex items-center ${
            isExiting ? 'animate-slide-out-down' : 'animate-slide-in-up'
          }`}
        >
          <div className='pr-48 flex items-center gap-10'>
            <img src={IconTick} alt='' />
            <div className='relative'>
              <p>
                Invoices <strong className='px-6 text-14 inline-block font-bold text-primary'>{issue?.invoiceNumber}</strong> is now under review.
              </p>
              <p className='mt-4 text-12 text-neutral-60'>
                The Support Team will be in touch with you shortly. <br />
                Issue {issue?.issueId} has been created.
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalContact;
