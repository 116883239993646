import { FC, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import { AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { IUploadSectionProps } from './types';

const StyledAccordion = styled(MuiAccordion)({
  borderRadius: '12px!important',
  margin: '0!important',
  boxShadow: 'none',
  border: '1px solid #DFDFDF',
  backgroundColor: '#ffffff',
  padding: '0px 24px 0',
  '&:before': {
    display: 'none',
  },
});

const StyledAccordionSummary = styled(AccordionSummary)({
  padding: '12px 0',
  fontSize: '14px',
  lineHeight: '24px',
  fontWeight: 'bold',
  color: '#606060',
  backgroundColor: 'transparent',
  borderRadius: '0!important',
  borderBottom: 'none!important',

  '&.Mui-expanded': {
    margin: 0,
    minHeight: 0,
    borderRadius: '8px 8px 0 0!important',
    borderBottom: '1px solid #DFDFDF!important',
  },

  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
    border: 'none',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 'bold',
    color: '#606060',

    '&.Mui-expanded': {
      margin: 0,
      minHeight: 0,
    },
  },

  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(270deg)',

    '&.Mui-expanded': {
      transform: 'rotate(0deg)',
    },

    '& .MuiSvgIcon-root': {
      color: '#606060',
    },
  },
});

const StyledAccordionDetails = styled(AccordionDetails)({
  padding: '24px 0',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

export const UploadSection: FC<IUploadSectionProps> = ({ header, children, defaultExpanded = false }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded);

  useEffect(() => {
    setIsExpanded(defaultExpanded);
  }, [defaultExpanded]);

  return (
    <StyledAccordion expanded={isExpanded} onChange={() => setIsExpanded(!isExpanded)}>
      <StyledAccordionSummary className='border-none border-0' expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.8rem', color: '#ffffff' }} />}>
        {header}
      </StyledAccordionSummary>

      <StyledAccordionDetails>{children}</StyledAccordionDetails>
    </StyledAccordion>
  );
};
