import { beautifyEnum } from 'src/utils/utility';
import { TransportMedium } from '../card/types';
import TransportMediumIcon from '../card/TransportMediumIcon';
import { useUploadSelector } from './useUploadSelector';

export const TopInfo = () => {
  const { modality, serviceProvider } = useUploadSelector();

  return (
    <div className='pt-12'>
      {serviceProvider && modality && (
        <>
          <div className='flex items-center gap-10'>
            <div className='flex items-center justify-center'>
              <TransportMediumIcon medium={modality as TransportMedium} iconColor='#9F9F9F' />
            </div>
            <h2 className='text-20 font-bold text-blue_grey-80 max-lg:text-18'>{beautifyEnum(serviceProvider)?.toUpperCase()}</h2>
            <div className='w-4 h-4 rounded-full bg-[#D9D9D9]' />
            <div className='uppercase text-neutral-60 text-16'>{modality}</div>
          </div>
        </>
      )}
      <h3 className='text-18 font-bold mt-12'>New Invoice Upload & Processing</h3>
      <p className='text-14 text-blue_grey-80 font-medium mt-5'>Upload new invoices and get them processed into the system</p>
    </div>
  );
};
