/**
 * Template Root
 */
import { Outlet } from 'react-router-dom';

import { SnackbarProvider } from 'notistack';
import { ErrorBoundary } from 'react-error-boundary';
import PageError from 'src/containers/PageError';
import { UploadInvoice } from '../molecules/uploadInvoice/Upload';

import { ToastResult } from '../molecules/toast/ToastResult';
import { ModalContact } from '../molecules/contact/ModalContact';
import { RecalculationToast } from '../organisms/actionDetails/recalculationToast/RecalculationToast';

declare module 'notistack' {
  interface VariantOverrides {
    recalculcation: {
      invoiceId: string;
      workflowStatus: string;
      invoiceNumber: string;
      shipmentNumber?: string;
      deviations?: Record<string, number>;
    };
  }
  interface OptionsObject {
    invoiceId: string;
    workflowStatus: string;
    invoiceNumber: string;
    shipmentNumber?: string;
    deviations?: Record<string, number>;
  }
}

const TemplateRoot: React.FC = () => (
  <ErrorBoundary fallback={<PageError />}>
    <SnackbarProvider
      maxSnack={2}
      Components={{
        recalculcation: RecalculationToast,
      }}
    >
      <Outlet />
      <UploadInvoice />
      <ToastResult />
      <ModalContact />
    </SnackbarProvider>
  </ErrorBoundary>
);

export default TemplateRoot;
