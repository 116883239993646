/**
 * components/organisms/Dashboard/TableActions.jsx
 */
import EyeIcon from 'src/assets/images/icons/eye.svg';
import WarningDispute from 'src/assets/images/icons/warning-dispute.svg';
import Upload from 'src/assets/images/icons/upload.svg';
import ArrowRight from 'src/assets/images/icons/arrow-right.svg';
import ClockRevert from 'src/assets/images/icons/clock_revert.svg';

import { useNavigate } from 'react-router-dom';
import { InvoiceDto } from 'src/generated/services/TFinancialApi';
import clsx from 'clsx';

export interface TDataBase {
  redirectId?: string;
  permissions?: string[];
  invoiceStatusOverview?: InvoiceDto['invoiceStatusOverview'];
  status?: InvoiceDto['status'];
  userResolutionStatus?: InvoiceDto['userResolutionStatus'];
  hasResponsibleWorkflow?: boolean;
}

export interface ITableActionsProps<TData extends TDataBase> {
  data: TData;
}

const getActionIcon = (isHavePermission: boolean, isDisable: boolean, overview: InvoiceDto['invoiceStatusOverview']['overview']) => {
  if (isDisable) {
    return <img src={ClockRevert} alt='ClockRevert' />;
  }
  if (isHavePermission) {
    if (overview === 'Dispute') {
      return <img src={WarningDispute} alt='WarningDispute' />;
    }
    if (overview === 'Credit Note') {
      return <img src={Upload} alt='Upload' />;
    }
    return <img src={ArrowRight} alt='ArrowRight' />;
  }
  return <img src={EyeIcon} alt='EyeIcon' />;
};

const TableActions = <TData extends TDataBase>(props: ITableActionsProps<TData>) => {
  const { data } = props;
  const navigate = useNavigate();
  const isHavePermission = data?.hasResponsibleWorkflow || false;
  const { invoiceStatusOverview } = data;
  const isDisabled = data?.userResolutionStatus && data?.userResolutionStatus !== 'FIXED';

  // Handle the case when status is not present or undefined
  return (
    <button
      type='button'
      disabled={isDisabled}
      className={clsx(isDisabled && 'cursor-not-allowed opacity-40')}
      onClick={() => {
        navigate(`/action-details/${data.redirectId}`);
      }}
    >
      {getActionIcon(isHavePermission, isDisabled, invoiceStatusOverview?.overview)}
    </button>
  );
};

export default TableActions;
