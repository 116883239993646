import { format } from 'date-fns';
import {
  InvoiceDisputeUpdateRequestDto,
  InvoiceSearchRequestDto,
  DisputeDeviationResolutionDto,
  ResponseDtoInvoiceSearchResponseDto,
  FiltersRequestDto,
  InvoiceSummaryDto,
} from 'src/generated/services/TFinancialApi';
import { ITableData, ITableParams } from 'src/use-cases/dashboard/useDataTable';
import { IDisputeTableItem } from 'src/presentations/components/organisms/actionDetails/dispute/types';

export const tableParamsToInvoiceSearchRequest = (tableParams: ITableParams): InvoiceSearchRequestDto => {
  const apiParams = {
    page: {
      pageNumber: tableParams?.page || 1,
      pageSize: 25,
    },
    sorting: {
      field: tableParams?.orderBy || 'INVOICE_DATE',
      sortingDirection: tableParams?.orderMethod === 'asc' ? 'ASC' : 'DESC',
    },
    searchFilters: tableParams?.filters.filters,
    invoiceStatusCategory: tableParams?.filters.invoiceStatusCategory,
    invoiceStatusSet: tableParams?.filters.invoiceStatusSet,
    dateReference: tableParams?.filters.dateReference,
    currencyReference: tableParams?.filters.currencyReference,
    invoiceNumber: '',
  };

  if (tableParams?.keyword) {
    apiParams.invoiceNumber = tableParams?.keyword;
  }
  return apiParams as InvoiceSearchRequestDto;
};

export const invoiceSearchResponseToTableData = (response: ResponseDtoInvoiceSearchResponseDto): ITableData => {
  const total = response?.data?.totalRecord;

  const invoices = response?.data?.invoices?.map((item) => ({
    ...item,
    redirectId: item.id,
  }));

  const stats = response?.data?.stats;

  return { items: invoices || [], total: total || 0, stats };
};

export const tableFiltersToInvoiceDashboardFilters = (filterOptions: Record<string, any> = null): FiltersRequestDto => {
  const apiParams = {
    filters: filterOptions?.filters,
    invoiceStatusSet: filterOptions?.invoiceStatusSet,
    dateReference: filterOptions?.dateReference,
    currencyReference: filterOptions?.currencyReference,
  };

  return apiParams as FiltersRequestDto;
};

export const DisputeFormToBody = (formValue, id: string): InvoiceDisputeUpdateRequestDto => ({
  id,
  lumpSumAmount: formValue.totalAmount ? Number(Number.parseFloat(formValue.totalAmount).toFixed(2)) : 0,
  agreementResolution: formValue.agreementType,
  deviationResolutionType: formValue.totalAmount && formValue.totalAmount !== '0' ? 'LUMP_SUM' : null,
  resolutionDescription: formValue.reason,
  deviationResolutions: formValue.disputeTableItems?.map(
    (item: IDisputeTableItem): DisputeDeviationResolutionDto => ({
      deviationId: item.deviationId,
      startPeriod: item.validityPeriod ? format(item.validityPeriod?.start, 'yyyy-MM-dd') : null,
      endPeriod: item.validityPeriod ? format(item.validityPeriod?.end, 'yyyy-MM-dd') : null,
      expectedAmount: item.expectedAmount,
      periodType: item.oneOffOrRecurring,
    })
  ),
});

export const invoiceFinalResolutionMapper = (finalResolution: InvoiceSummaryDto['finalResolution'] | null): string => {
  const invoiceFinalResolutionMap: Record<InvoiceSummaryDto['finalResolution'], string> = {
    DUPLICATE_INVOICE: 'Duplicated Invoice',
    NO_RATE_CARD_FOUND: 'No Rate Card Found',
    NO_CONTRACT_REJECT: 'Invoice rejected due to No Rate Card Found',
    REJECT_AFTER_DISPUTE: 'Invoice Rejected after Dispute',
    UNKNOWN_BILLING_PARTY: 'Unknown Billed Party',
    WRONG_BILLING_PARTY: 'Wrong Billed Party',
  };

  return invoiceFinalResolutionMap[finalResolution] ?? 'Invoice Rejected';
};
