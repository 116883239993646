import { useCallback, useState } from 'react';
import { InputBase, IconButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from 'src/assets/images/icons/search-normal.svg';
import debounce from 'lodash/debounce';
import { useGeneralSearch } from 'src/use-cases/dashboard/useGeneralSearch';
import { ResultRecord } from 'src/generated/services/TFinancialApi';
import { SearchBarResults } from './Results';

const SearchBarRoot = styled(Box, { shouldForwardProp: (prop) => prop !== 'isFocused' })(({ isFocused }: { isFocused?: boolean }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '4px 4px 4px 8px',
  height: '40px',
  background: '#30383C',
  border: isFocused ? '1px solid #9F9F9F' : '1px solid #495053',
  borderRadius: '8px',
  fontSize: '14px',
  fontWeight: '400',
  position: 'relative',
}));

const SearchInput = styled(InputBase)({
  flexGrow: 1,
  fontSize: '14px',
  fontWeight: '400',
  color: '#eeeeee',
  '&.focused input::placeholder': {
    color: '#9F9F9F',
    opacity: 1,
  },
  '&.not-focused input::placeholder': {
    color: '#9F9F9F',
    opacity: 1,
  },
});

const SearchIconButton = styled(IconButton)(() => ({
  backgroundColor: '#00CC3A',
  borderRadius: '4px',
  width: '32px',
  height: '32px',
  padding: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: '#00CC3A',
  },
}));

const SearchIconImage = styled('img')(() => ({
  width: '20px',
  height: '20px',
}));

interface SearchBarProps {
  placeholder: string;
  onSubmit: (keyword: string) => void;
  currentKeyword: string;
}

export const SearchBar: React.FC<SearchBarProps> = ({ placeholder, onSubmit, currentKeyword }) => {
  const [keyword, setKeyword] = useState(currentKeyword ?? '');
  const [isFocused, setIsFocused] = useState(false);
  const [searchResult, setSearchResult] = useState<ResultRecord[]>([]);
  const [isShowContent, setIsShowContent] = useState(false);
  const [totalRecord, setTotalRecord] = useState(0);
  const [page, setPage] = useState(1);
  const [onlyShowApproved, setOnlyShowApproved] = useState(false);

  const { mutateAsync: generalSearch, isLoading: isLoadingSearch } = useGeneralSearch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onInputChange = useCallback(
    debounce(async (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target?.value?.toUpperCase();
      if (value && value?.length >= 3) {
        const result = await generalSearch({
          searchKey: value,
          pageNumber: page,
          pageSize: 10,
          isApprovedInvoice: onlyShowApproved,
        });

        setKeyword(value);
        setSearchResult(result?.data?.data?.results ?? []);
        setTotalRecord(result?.data?.data?.totalRecord ?? 0);
        setIsShowContent(true);
      } else {
        setSearchResult([]);
        setTotalRecord(0);
        setIsShowContent(false);
        setPage(1);
      }
    }, 1000),
    []
  );

  const onChangeOnlyShowApproved = useCallback(
    async (status: boolean) => {
      setOnlyShowApproved(status);

      const result = await generalSearch({
        searchKey: keyword,
        pageNumber: 0,
        pageSize: 10,
        isApprovedInvoice: status,
      });

      setSearchResult(result?.data?.data?.results ?? []); // Update results based on the toggle
      setTotalRecord(result?.data?.data?.totalRecord ?? 0); // Update the total record count
    },
    [generalSearch, keyword]
  );

  const onLoadMore = useCallback(async () => {
    // Prevent API call if all records are already loaded
    if (searchResult.length >= totalRecord) {
      return;
    }
    const newPage = page + 1;
    setPage(newPage);
    const result = await generalSearch({
      searchKey: keyword,
      pageNumber: newPage,
      pageSize: 10,
      isApprovedInvoice: onlyShowApproved,
    });

    setSearchResult((current) => [...current, ...(result?.data?.data?.results ?? [])]);
  }, [page, keyword, generalSearch, onlyShowApproved, searchResult, totalRecord]);

  const onClearSearch = useCallback(async () => {
    setKeyword('');
    setSearchResult([]);
    setTotalRecord(0);
    setIsShowContent(false);
    setPage(1);
  }, []);

  return (
    <div className='relative'>
      <SearchBarRoot isFocused={isFocused}>
        <SearchInput
          className={isFocused ? 'focused' : 'not-focused'}
          placeholder={placeholder}
          value={keyword}
          onChange={(event) => {
            setKeyword(event.target.value);
          }}
          onInput={onInputChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />

        {keyword && keyword !== '' && (
          <div className='absolute top-[3px] right-[38px]'>
            <IconButton disabled={isLoadingSearch} onClick={onClearSearch}>
              <CloseIcon sx={{ fontSize: 18, color: '#9F9F9F' }} />
            </IconButton>
          </div>
        )}

        <SearchIconButton className='pointer-events-none' disabled={isLoadingSearch} type='button' onClick={() => onSubmit(keyword)}>
          <SearchIconImage src={SearchIcon} alt='Search' />
        </SearchIconButton>
      </SearchBarRoot>
      <SearchBarResults
        results={searchResult}
        totalRecord={totalRecord}
        isLoading={isLoadingSearch}
        isShowContent={isShowContent || (isFocused && searchResult?.length > 0)}
        setIsShowContent={setIsShowContent}
        onLoadMore={onLoadMore}
        hasMore={searchResult.length < totalRecord}
        isOnlyShowApproved={onlyShowApproved}
        onChangeOnlyShowApproved={onChangeOnlyShowApproved}
      />
    </div>
  );
};
