import { ICustomIconProps } from './types';

const IconCheckCircle: React.FC<ICustomIconProps> = ({ size = 16, color = '#00992B', className }) => (
  <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M8.00016 1.33203C4.32683 1.33203 1.3335 4.32536 1.3335 7.9987C1.3335 11.672 4.32683 14.6654 8.00016 14.6654C11.6735 14.6654 14.6668 11.672 14.6668 7.9987C14.6668 4.32536 11.6735 1.33203 8.00016 1.33203ZM11.1868 6.46536L7.40683 10.2454C7.3135 10.3387 7.18683 10.392 7.0535 10.392C6.92016 10.392 6.7935 10.3387 6.70016 10.2454L4.8135 8.3587C4.62016 8.16536 4.62016 7.84536 4.8135 7.65203C5.00683 7.4587 5.32683 7.4587 5.52016 7.65203L7.0535 9.18536L10.4802 5.7587C10.6735 5.56536 10.9935 5.56536 11.1868 5.7587C11.3802 5.95203 11.3802 6.26536 11.1868 6.46536Z'
      fill={color}
    />
  </svg>
);

export default IconCheckCircle;
