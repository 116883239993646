import { Link } from 'react-router-dom';
import { useCallback, forwardRef } from 'react';

import IconTick from 'src/assets/images/icons/tick-circle-primary.svg';
import IconDanger from 'src/assets/images/icons/danger-alt.svg';
import IconDocAccept from 'src/assets/images/icons/document-accept.svg';
import IconDocWarning from 'src/assets/images/icons/document-warning.svg';
import IconClose from 'src/assets/images/icons/close-rounded.svg';
import { beautifyEnum } from 'src/utils/utility';

import { SnackbarContent, closeSnackbar } from 'notistack';
import { IToastResultProps } from './types';

export const RecalculationToast = forwardRef<HTMLDivElement, IToastResultProps>((props, ref) => {
  const { id, workflowStatus, invoiceId, invoiceNumber, shipmentNumber, deviations } = props;

  const getInvoiceIcon = useCallback(() => {
    switch (workflowStatus) {
      case 'RECALCULATION_REJECT':
        return <img src={IconDanger} alt='' className='w-full' />;
      case 'RECALCULATION_RESOLVED':
        return <img src={IconDocAccept} alt='' className='w-full' />;
      case 'RECALCULATION_OPERATION_REVIEW':
      case 'RECALCULATION_PROCUREMENT_REVIEW':
        return <img src={IconDocWarning} alt='' className='w-full' />;
      default:
        return <img src={IconTick} alt='' className='w-full' />;
    }
  }, [workflowStatus]);

  const getInvoiceStatus = useCallback(() => {
    let status = 'New Status';

    switch (workflowStatus) {
      case 'RECALCULATION_READY_FOR_PAYMENT':
        status = 'Ready for Payment';
        break;
      case 'RECALCULATION_REJECT':
        status = 'Rejected';
        break;
      case 'RECALCULATION_RESOLVED':
        status = 'Resolved';
        break;
      case 'RECALCULATION_PROCUREMENT_REVIEW':
        status = 'pending Procurement Review';
        break;
      case 'RECALCULATION_OPERATION_REVIEW':
        status = 'pending Logistics Review';
        break;
      default:
        status = 'New Status';
        break;
    }

    return status;
  }, [workflowStatus]);

  const isShowDeviations = workflowStatus !== 'RECALCULATION_REJECT' && workflowStatus !== 'RECALCULATION_READY_FOR_PAYMENT';

  return (
    <SnackbarContent ref={ref} className='py-14 pl-20 pr-24 rounded-8 bg-[#202020]'>
      <div className='flex gap-12'>
        <div className='w-32'>{getInvoiceIcon()}</div>
        <div className='flex-1'>
          {workflowStatus === 'RECALCULATION_READY_FOR_PAYMENT' && (
            <h3 className='text-neutral-40 font-medium text-14 mb-2'>
              Invoice <strong className='text-white'>No. {invoiceNumber}</strong> has been marked as {getInvoiceStatus()} after recalculations
            </h3>
          )}

          {workflowStatus === 'RECALCULATION_REJECT' && (
            <h3 className='text-neutral-40 font-medium text-14 mb-2'>
              Invoice <strong className='text-white'>No. {invoiceNumber}</strong> has been marked as {getInvoiceStatus()}
            </h3>
          )}

          {workflowStatus !== 'RECALCULATION_READY_FOR_PAYMENT' && workflowStatus !== 'RECALCULATION_REJECT' && (
            <h3 className='text-neutral-40 font-medium text-14 mb-2'>
              The Shipment <strong className='text-white'>No. {shipmentNumber}</strong> from Invoice <strong className='text-white'>No. {invoiceNumber}</strong>{' '}
              is {getInvoiceStatus()} after recalculations
            </h3>
          )}

          {deviations && isShowDeviations && (
            <div className='mt-8 bg-[#3B3B3B] rounded-full py-4 px-12 inline-flex items-center gap-12'>
              {Object.entries(deviations).map(([key, value]) => (
                <div
                  key={`${invoiceId}-${key}`}
                  className='text-neutral-50 text-12 flex items-center gap-12 after:w-1 after:h-10 after:bg-[#535353] last:after:hidden'
                >
                  <span>
                    {value} {beautifyEnum(key)}
                  </span>
                </div>
              ))}
            </div>
          )}
          {!isShowDeviations && <p className='text-12 text-neutral-50 mt-6'>It is now available in Report & Analytics</p>}

          <div className='mt-12'>
            <Link
              to={`/action-details/${invoiceId}?hideBack=1`}
              className='text-primary font-medium text-12'
              target='_blank'
              onClick={() => {
                // hideToastResult();
              }}
            >
              See Invoice
            </Link>
          </div>
        </div>
        <div>
          <button type='button' onClick={() => closeSnackbar(id)} className='ml-24 w-24 h-24 flex items-center justify-center '>
            <img src={IconClose} alt='Close' />
          </button>
        </div>
      </div>
    </SnackbarContent>
  );
});
