import { ICustomIconProps } from './types';

const IconInfoCircle: React.FC<ICustomIconProps> = ({ size = 16, color = '#E72547', className }) => (
  <svg width={size} height={size} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
    <path
      d='M8.00065 1.33301C4.32732 1.33301 1.33398 4.32634 1.33398 7.99967C1.33398 11.673 4.32732 14.6663 8.00065 14.6663C11.674 14.6663 14.6673 11.673 14.6673 7.99967C14.6673 4.32634 11.674 1.33301 8.00065 1.33301ZM7.50065 5.33301C7.50065 5.05967 7.72732 4.83301 8.00065 4.83301C8.27398 4.83301 8.50065 5.05967 8.50065 5.33301V8.66634C8.50065 8.93967 8.27398 9.16634 8.00065 9.16634C7.72732 9.16634 7.50065 8.93967 7.50065 8.66634V5.33301ZM8.61398 10.9197C8.58065 11.0063 8.53398 11.073 8.47398 11.1397C8.40732 11.1997 8.33398 11.2463 8.25398 11.2797C8.17398 11.313 8.08732 11.333 8.00065 11.333C7.91398 11.333 7.82732 11.313 7.74732 11.2797C7.66732 11.2463 7.59398 11.1997 7.52732 11.1397C7.46732 11.073 7.42065 11.0063 7.38732 10.9197C7.35398 10.8397 7.33398 10.753 7.33398 10.6663C7.33398 10.5797 7.35398 10.493 7.38732 10.413C7.42065 10.333 7.46732 10.2597 7.52732 10.193C7.59398 10.133 7.66732 10.0863 7.74732 10.053C7.90732 9.98634 8.09398 9.98634 8.25398 10.053C8.33398 10.0863 8.40732 10.133 8.47398 10.193C8.53398 10.2597 8.58065 10.333 8.61398 10.413C8.64732 10.493 8.66732 10.5797 8.66732 10.6663C8.66732 10.753 8.64732 10.8397 8.61398 10.9197Z'
      fill={color}
    />
  </svg>
);

export default IconInfoCircle;
