import React from 'react';
import { SimpleFormatNumber } from 'src/utils/utility';

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  label?: string;
  title?: string;
  formatter?: (tick: string) => string;
}

const CircleIcon = ({ color }: any) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'>
    <circle cx='5' cy='5' r='5' fill={color} />
  </svg>
);

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, title, formatter }) => {
  if (active && payload?.length) {
    return (
      <div className='bg-[#192333] text-grey-40 min-w-[350px] pe-15 py-15 rounded shadow-sm'>
        <div className='flex gap-10 w-full'>
          <div className='flex justify-end gap-3 w-2/3 text-gray-400'>{title}</div>
          <p className='w-1/3 font-semibold text-white'>{formatter(label)}</p>
        </div>
        {payload.map((entry) => (
          <div className='flex gap-10 w-full' key={entry.dataKey}>
            <div className='flex justify-end items-center gap-3 w-2/3 '>
              <CircleIcon color={entry.color} /> {entry.name}
            </div>
            <p className='w-1/3 font-semibold text-white'>{SimpleFormatNumber(entry.value)}</p>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
