import { FC, useMemo, useState } from 'react';
import { Button } from '@mui/material';
import { InvoiceFileProcessingStatusDto, InvoiceUploadSignedUrlDto } from 'src/generated/services/TFinancialApi';

import IconCollapse from 'src/assets/images/icons/grid-2.svg';
import IconExpand from 'src/assets/images/icons/list.svg';

import { UploadStatusItem } from './UploadStatusItem';
import { useUploadSelector } from './useUploadSelector';
import { UploadSectionProvider } from './SectionProviders';
import { IGroupingItemData, IGroupingItems } from './types';
import { UploadSectionLoader } from './SectionLoader';

export interface IStepUploadStatusProps {
  onFinishUpload(data: InvoiceUploadSignedUrlDto): void;
}

export const StepUploadStatus: FC<IStepUploadStatusProps> = ({ onFinishUpload }) => {
  const { uploadResults, uploadProcessings } = useUploadSelector();
  const [, setIsCollapseAll] = useState(false);
  const [isExpandAll, setIsExpandAll] = useState(false);

  const uploadResultItems = useMemo(() => {
    const groupingItems: IGroupingItems = {};
    uploadResults?.forEach((result: InvoiceUploadSignedUrlDto) => {
      const invoiceDetail = uploadProcessings?.find(
        (item: InvoiceFileProcessingStatusDto) => item?.invoiceFileProcessingId === result?.dataPipelineProcessingId && item?.serviceProviderCode
      );

      if (invoiceDetail) {
        const serviceProviderCode = invoiceDetail?.serviceProviderCode;
        if (!groupingItems[serviceProviderCode]) {
          groupingItems[serviceProviderCode] = {
            results: [],
            invoiceDetail,
          };
        }
        groupingItems[serviceProviderCode].results.push(result);
      }
    });
    return groupingItems;
  }, [uploadResults, uploadProcessings]);

  const progressResultItems: IGroupingItemData[] = useMemo(() => {
    const results: IGroupingItemData[] = [];
    uploadResults?.forEach((result: InvoiceUploadSignedUrlDto) => {
      const invoiceDetail = uploadProcessings?.find(
        (item: InvoiceFileProcessingStatusDto) => item?.invoiceFileProcessingId === result?.dataPipelineProcessingId
      );

      if (invoiceDetail && !invoiceDetail?.serviceProviderCode) {
        results.push({
          results: [result],
          invoiceDetail,
        });
      }
    });
    return results;
  }, [uploadResults, uploadProcessings]);

  return (
    <div className='relative'>
      <div className='flex items-center justify-between'>
        <div className='flex-1'>
          <h3 className='text-18 font-bold mb-4'>Upload & Processing Status: {uploadResults?.length || 0} Items</h3>
          <p className='text-14'>Feel free to close this window while invoices are being processed.</p>
        </div>

        <div className='ml-auto flex items-center gap-16'>
          <Button
            type='button'
            variant='outlined'
            color='inherit'
            onClick={() => {
              setIsCollapseAll(true);
              setIsExpandAll(false);
            }}
            sx={{ textTransform: 'capitalize', borderColor: '#dfdfdf', backgroundColor: '#fff', gap: '12px' }}
          >
            Colapse All
            <img src={IconCollapse} alt='' />
          </Button>
          <Button
            type='button'
            variant='outlined'
            color='inherit'
            onClick={() => {
              setIsExpandAll(true);
              setIsCollapseAll(false);
            }}
            sx={{ textTransform: 'capitalize', borderColor: '#dfdfdf', backgroundColor: '#fff', gap: '12px' }}
          >
            Expand All
            <img src={IconExpand} alt='' />
          </Button>
        </div>
      </div>

      <div className='mt-24 flex flex-col gap-24'>
        {Object.entries(uploadResultItems).map(([, { results, invoiceDetail }]) => (
          <UploadSectionProvider data={{ results, invoiceDetail }} defaultExpanded={isExpandAll}>
            {results?.map((result) => (
              <UploadStatusItem
                isDisableUpload
                key={`result-${result?.fileName}`}
                data={result}
                onFinish={onFinishUpload}
                invoiceDetail={invoiceDetail || null}
              />
            ))}
          </UploadSectionProvider>
        ))}

        {progressResultItems?.length > 0 && (
          <UploadSectionLoader defaultExpanded={isExpandAll}>
            {progressResultItems?.map(({ results, invoiceDetail }) => (
              <UploadStatusItem key={`result-${results?.[0]?.fileName}`} data={results?.[0]} onFinish={onFinishUpload} invoiceDetail={invoiceDetail || null} />
            ))}
          </UploadSectionLoader>
        )}
      </div>
    </div>
  );
};
