import { FC } from 'react';

import IconRefresh from 'src/assets/images/icons/refresh.svg';
import { IUploadSectionLoaderProps } from './types';
import { UploadSection } from './Section';

export const UploadSectionLoader: FC<IUploadSectionLoaderProps> = (props) => {
  const { children, defaultExpanded = false } = props;

  return (
    <UploadSection
      defaultExpanded={defaultExpanded}
      header={
        <div className='flex items-center gap-16'>
          <div className='w-[28px] h-[28px] rounded-full bg-[#EBEEF0] border border-[#597282] flex items-center justify-center'>
            <img src={IconRefresh} className='animate-spin w-[16px]' alt='' />
          </div>
          <div className='flex-1 text-16 font-medium text-neutral-70'>Loading / Processing</div>
        </div>
      }
    >
      {children}
    </UploadSection>
  );
};
