import { useMaterialReactTable } from 'material-react-table';
import { formatDateString } from 'src/utils/date';
import { beautifyEnum, formatNumber, SimpleFormatNumber } from 'src/utils/utility';
import EyeIcon from 'src/assets/images/icons/eye.svg';
import { CO2, Kg, M3, Ton } from 'src/presentations/components/atom/unit';
import StatusChip from 'src/presentations/components/organisms/dashboard/StatusChip';
import { CustomTooltip } from 'src/presentations/components/atom/customtooltip';
import React, { ReactNode } from 'react';
import { shipmentTypeIcons } from '../../../molecules/table/NewInvoiceTable.tsx';
import { InvoiceAnalyticsDto } from '../../../../../generated/services/TFinancialApi.ts';

export const useAnalyticsTable = (tableOptions) =>
  useMaterialReactTable({
    enableSorting: false,
    enablePinning: false,
    enableRowDragging: false,
    enableRowSelection: false,
    enableColumnActions: false,
    enablePagination: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableSortingRemoval: false,
    enableEditing: true,
    editDisplayMode: 'table',
    muiTableHeadCellProps: {
      align: 'center',
    },
    muiTableBodyCellProps: {
      align: 'center',
    },
    ...tableOptions,
  });

export const getShipmentsColumns = ({ dateReference, currentCurrency }: { dateReference: string; currentCurrency?: string }) => {
  dateColumnOptions.find((option) => option.value === dateReference);
  return [
    {
      enableEditing: false,
      enableSorting: false,
      accessorFn: (row) => shipmentTypeIcons[row.shipmentType],
      accessorKey: 'aggregateShipmentType',
      header: ' ',
      size: 90,
      Cell: ({ cell, row }) => {
        const shipmentTypeDisplay = (() => {
          switch (row.original.shipmentType?.toLowerCase()) {
            case 'import':
              return 'INBOUND';
            case 'export':
              return 'OUTBOUND';
            default:
              return row.original.shipmentType || 'MULTI';
          }
        })();

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CustomTooltip title={shipmentTypeDisplay}>
              <div>{(cell.getValue() as ReactNode) || null}</div>
            </CustomTooltip>
          </div>
        );
      },
    },
    {
      enableEditing: false,
      accessorKey: 'shipmentNumber',
      accessorFn: (row) => row.shipmentNumber || '-',
      header: 'SHIP NO.',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'serviceType',
      accessorFn: (row) => row.serviceType || '-',
      header: 'SERVICE',
      size: 20,
    },
    {
      enableEditing: false,
      enableSorting: true,
      accessorKey: 'date',
      accessorFn: (row) => {
        let date: string;
        switch (dateReference) {
          case 'PICKUP_DATE':
            date = row.pickupDate;
            break;
          case 'DELIVERY_DATE':
            date = row.deliveryDate;
            break;
          case 'INVOICE_DATE':
            date = row.invoiceDate;
            break;
          case 'INVOICE_DUE_DATE':
            date = row.invoiceDueDate;
            break;
          case 'SUBMISSION_DATE':
            date = row.submissionDate;
            break;
          default:
            throw new Error('Invalid date reference');
        }
        return formatDateString(date);
      },
      header: 'DATE',
      Header: (
        <div style={{ textAlign: 'center' }}>
          <div>DATE</div>
          <div style={{ fontSize: '0.75rem', color: '#808080', fontWeight: 'lighter' }}>{beautifyEnum(dateReference)}</div>
        </div>
      ),
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'invoiceCount',
      accessorFn: (row) => row.invoiceCount || '-',
      header: 'NO. INVOICES',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'invoiceNumber',
      accessorFn: (row) => row.invoiceNumber || '-',
      header: 'INVOICE NO.',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'invoiceStatus',
      accessorFn: (row) => <StatusChip status={row.invoiceStatus} /> || '-',
      header: 'INVOICE STATUS',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'serviceProvider',
      accessorFn: (row) => beautifyEnum(row.serviceProvider) || '-',
      header: 'SERVICE PROVIDER',
      size: 150,
    },
    {
      enableEditing: false,
      accessorKey: 'modality',
      accessorFn: (row) => row.modality || '-',
      header: 'MODALITY',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'billedEntity',
      accessorFn: (row) => row.billedEntity || '-',
      header: 'BILLED ENTITY',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'invoiceAmountAfterTax',
      accessorFn: (row) => `${row?.invoiceCurrency || '-'} ${formatNumber(row?.invoiceAmountAfterTax) || '-'}`,
      header: (
        <div>
          <div>SHIP. AMOUNT</div>
          <span className='text-xs font-medium leading-4 text-[#808080]'>Invoice Currency</span>
        </div>
      ),
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'totalAmount',
      accessorFn: (row) => {
        const currency = row?.totalAmountCurrency || currentCurrency;
        return `${currency || '-'} ${formatNumber(row?.totalAmount) || '-'}`;
      },
      header: (
        <div>
          <div>SHIP. AMOUNT</div>
          <span className='text-xs font-medium leading-4 text-[#808080]'>Currency Reference</span>
        </div>
      ),
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'totalChargeAmountLeg1',
      accessorFn: (row) => {
        const currency = row?.invoiceCurrency;
        const amount = row?.totalChargeAmountLeg1;
        if (!currency || amount == null) {
          return '-';
        }
        return `${currency} ${formatNumber(amount)}`;
      },
      header: (
        <div>
          <div>TOTAL CHARGE AMOUNT</div>
          <span className='text-xs font-medium leading-4 text-[#808080]'>Leg 1 - Invoice Currency</span>
        </div>
      ),
      size: 250,
    },
    {
      enableEditing: false,
      accessorKey: 'totalChargeAmountLeg2',
      accessorFn: (row) => {
        const currency = row?.invoiceCurrency;
        const amount = row?.totalChargeAmountLeg2;
        if (!currency || amount == null) {
          return '-';
        }
        return `${currency} ${formatNumber(amount)}`;
      },
      header: (
        <div>
          <div>TOTAL CHARGE AMOUNT</div>
          <span className='text-xs font-medium leading-4 text-[#808080]'>Leg 2 - Invoice Currency</span>
        </div>
      ),
      size: 250,
    },
    {
      enableEditing: false,
      accessorKey: 'totalChargeAmountLeg3',
      accessorFn: (row) => {
        const currency = row?.invoiceCurrency;
        const amount = row?.totalChargeAmountLeg3;

        if (!currency || amount == null) {
          return '-';
        }
        return `${currency} ${formatNumber(amount)}`;
      },
      header: (
        <div>
          <div>TOTAL CHARGE AMOUNT</div>
          <span className='text-xs font-medium leading-4 text-[#808080]'>Leg 3 - Invoice Currency</span>
        </div>
      ),
      size: 250,
    },
    {
      enableEditing: false,
      accessorKey: 'pol',
      accessorFn: (row) => row.pol || '-',
      header: 'POL',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'pod',
      accessorFn: (row) => row.pod || '-',
      header: 'POD ',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'grossWeight',
      accessorFn: (row) => (
        <span>
          {SimpleFormatNumber(row.grossWeight) || '-'} <Kg />
        </span>
      ),
      header: 'GROSS WEIGHT',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'chargeableWeight',
      accessorFn: (row) => (
        <span>
          {SimpleFormatNumber(row.chargeableWeight) || '-'} <Kg />
        </span>
      ),
      header: 'CHARGEABLE WEIGHT ',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'volume',
      accessorFn: (row) => (
        <span>
          {row.volume || '-'} <M3 />
        </span>
      ),
      header: 'VOLUME',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'shipper',
      accessorFn: (row) => beautifyEnum(row.shipper)?.toUpperCase() || '-',
      header: 'SHIPPER NAME',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'shipperCountry',
      accessorFn: (row) => row.shipperCountry || '-',
      header: 'SHIPPER COUNTRY',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'consignee',
      accessorFn: (row) => row.consignee || '-',
      header: 'CONSIGNEE NAME',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'goodsName',
      accessorFn: (row) => row.goodsName || '-',
      header: 'GOODS NAME',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'co2Emission',
      accessorFn: (row) => (
        <span>
          {row.co2Emission} <Ton />
        </span>
      ),
      header: (
        <>
          <CO2 /> EMISSION
        </>
      ),
      size: 150,
    },
    {
      enableEditing: false,
      accessorKey: 'vehicleId',
      accessorFn: (row) => row.vehicleId || '-',
      header: 'VEHICLE ID',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'shipmentCurrentDeviationWorkflow',
      accessorFn: (row) => beautifyEnum(row.shipmentCurrentDeviationWorkflow) || '-',
      header: 'REVIEW STAGE',
      size: 20,
    },
    {
      enableEditing: false,
      accessorFn: (row) => (
        <button type='button' onClick={() => window.open(`/action-details/${row.invoiceId}?hideBack=1`, '_blank')}>
          <img src={EyeIcon} alt='Detail' />
        </button>
      ),
      header: 'DETAILS',
      accessorKey: 'actions',
      enableSorting: false,
      size: 20,
    },
  ].filter(Boolean); // Filter out null/falsy columns
};

export const getInvoicesColumns = ({ dateReference, currentCurrency }: { dateReference: string; currentCurrency?: string }) =>
  [
    {
      enableEditing: false,
      enableSorting: false,
      accessorFn: (row) => shipmentTypeIcons[row.shipmentType],
      accessorKey: 'aggregateShipmentType',
      header: ' ',
      size: 90,
      Cell: ({ cell, row }) => {
        // Map shipmentType to the desired display text
        const shipmentTypeDisplay = (() => {
          switch (row.original.shipmentType?.toLowerCase()) {
            case 'import':
              return 'INBOUND';
            case 'export':
              return 'OUTBOUND';
            default:
              return row.original.shipmentType || 'MULTI';
          }
        })();

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CustomTooltip title={shipmentTypeDisplay}>
              <div>{(cell.getValue() as ReactNode) || null}</div>
            </CustomTooltip>
          </div>
        );
      },
    },
    {
      enableEditing: false,
      accessorKey: 'invoiceNumber',
      accessorFn: (row) => row.invoiceNumber || '-',
      header: 'INVOICE NO.',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'invoiceStatus',
      accessorFn: (row) => <StatusChip status={row.invoiceStatus} />,
      header: 'STATUS ',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'shipmentNumber',
      accessorFn: (row: InvoiceAnalyticsDto) => {
        if (row?.shipmentCount && row?.shipmentCount > 0) {
          if (row?.shipmentCount === 1) {
            return row?.shipmentNumber;
          }
          return `${row?.shipmentCount} Shipments`;
        }
        return '-';
      },
      header: 'SHIP NO.',
      size: 20,
    },
    {
      enableEditing: false,
      enableSorting: true,
      accessorKey: 'date',
      accessorFn: (row) => {
        let date: string;
        switch (dateReference) {
          case 'PICKUP_DATE':
            date = row.pickupDate;
            break;
          case 'DELIVERY_DATE':
            date = row.deliveryDate;
            break;
          case 'INVOICE_DATE':
            date = row.invoiceDate;
            break;
          case 'INVOICE_DUE_DATE':
            date = row.invoiceDueDate;
            break;
          case 'SUBMISSION_DATE':
            date = row.submissionDate;
            break;
          default:
            throw new Error('Invalid date reference');
        }
        return formatDateString(date);
      },
      header: 'DATE',
      Header: (
        <div style={{ textAlign: 'center' }}>
          <div>DATE</div>
          <div style={{ fontSize: '0.75rem', color: '#808080', fontWeight: 'lighter' }}>{beautifyEnum(dateReference)}</div>
        </div>
      ),
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'serviceProvider',
      accessorFn: (row) => beautifyEnum(row.serviceProvider) || '-',
      header: 'SERVICE PROVIDER',
      size: 150,
    },
    {
      enableEditing: false,
      accessorKey: 'modality',
      accessorFn: (row) => row.modality || '-',
      header: 'MODALITY',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'billedEntity',
      accessorFn: (row) => row.billedEntity || '-',
      header: 'BILLED ENTITY',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'accountNumbers',
      accessorFn: (row) => row.accountNumbers || '-',
      header: 'ACCOUNT NUMBER',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'totalAmount',
      accessorFn: (row) => `${row?.invoiceCurrency || '-'} ${formatNumber(row?.totalAmount) || '-'}`,
      header: (
        <div>
          <div>TOTAL AMOUNT</div>
          <span className='text-xs font-medium leading-4 text-[#808080]'>Invoice Currency</span>
        </div>
      ),
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'totalAmountInReferenceCurrency',
      accessorFn: (row) => {
        const currency = row?.invoiceReferenceCurrency || currentCurrency;
        return `${currency || '-'} ${formatNumber(row?.totalAmountInReferenceCurrency) || '-'}`;
      },
      header: (
        <div>
          <div>TOTAL AMOUNT</div>
          <span className='text-xs font-medium leading-4 text-[#808080]'>Currency Reference</span>
        </div>
      ),
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'fapRef',
      accessorFn: (row) => row?.fapRef || '-',
      header: 'FAP REF',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'resolutionDate',
      accessorFn: (row: { resolutionDate: string }) => formatDateString(row?.resolutionDate) || '-',
      header: 'RESOLUTION DATE',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'invoiceChargesCount',
      accessorFn: (row) => row.invoiceChargesCount || '-',
      header: 'NO. CHARGES',
      size: 20,
    },
    {
      enableEditing: false,
      accessorFn: (row) => (
        <button type='button' onClick={() => window.open(`/action-details/${row.invoiceId}?hideBack=1`)}>
          <img src={EyeIcon} alt='Detail' />
        </button>
      ),
      header: 'DETAILS',
      enableSorting: false,
      accessorKey: 'actions',
      size: 20,
    },
  ].filter(Boolean);

export const getChargesColumns = ({ dateReference, currentCurrency }: { dateReference: string; currentCurrency?: string }) =>
  [
    {
      enableEditing: false,
      accessorKey: 'chargeDomain',
      accessorFn: (row) => beautifyEnum(row.chargeDomain)?.toUpperCase() || '-',
      header: 'CHARGE DOMAIN',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'chargeName',
      accessorFn: (row) => row.chargeName || '-',
      header: 'CHARGE NAME',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'legType',
      accessorFn: (row) => {
        const legTypeMapping = {
          LEG_ONE: '1',
          LEG_TWO: '2',
          LEG_THREE: '3',
        };
        return legTypeMapping[row.legType] || '-';
      },
      header: 'LEG',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'serviceProvider',
      accessorFn: (row) => beautifyEnum(row.serviceProvider) || '-',
      header: 'SERVICE PROVIDER',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'modality',
      accessorFn: (row) => row.modality || '-',
      header: 'MODALITY',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'billedEntity',
      accessorFn: (row) => row.billedEntity || '-',
      header: 'BILLED ENTITY',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'deviationAmountInInvoiceCurrency',
      accessorFn: (row) => `${row?.invoiceCurrency || '-'} ${formatNumber(row?.deviationAmountInInvoiceCurrency) || '-'}`,
      header: (
        <div>
          <div>DEVIATION AMOUNT</div>
          <span className='text-xs font-medium leading-4 text-[#808080]'>Invoice Currency</span>
        </div>
      ),
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'chargeAmount',
      accessorFn: (row) => `${row?.chargeCurrency || '-'} ${formatNumber(row?.chargeAmount || '-')}`,
      header: (
        <div>
          <div>CHARGE AMOUNT</div>
          <span className='text-xs font-medium leading-4 text-[#808080]'>Original Currency</span>
        </div>
      ),
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'chargeAmountInInvoiceCurrency',
      accessorFn: (row) => `${row?.invoiceCurrency || '-'} ${formatNumber(row?.chargeAmountInInvoiceCurrency) || '-'}`,
      header: (
        <div>
          <div>CHARGE AMOUNT</div>
          <span className='text-xs font-medium leading-4 text-[#808080]'>Invoice Currency</span>
        </div>
      ),
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'chargeAmountInReferenceCurrency',
      accessorFn: (row) => {
        const currency = row?.referenceCurrency || currentCurrency;
        return `${currency || '-'} ${formatNumber(row?.chargeAmountInReferenceCurrency) || '-'}`;
      },
      header: (
        <div>
          <div>CHARGE AMOUNT</div>
          <span className='text-xs font-medium leading-4 text-[#808080]'>Currency Reference</span>
        </div>
      ),
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'chargeQuantity',
      accessorFn: (row) => row.chargeQuantity || '-',
      header: 'CHARGE QUANTITY',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'quantityUnit',
      accessorFn: (row) => row.quantityUnit || '-',
      header: 'QUANTITY UNIT',
      size: 150,
    },
    {
      enableEditing: false,
      accessorKey: 'chargeUnitPrice',
      accessorFn: (row) => `${row?.chargeCurrency || '-'} ${formatNumber(row?.chargeUnitPrice) || '-'}`,
      header: (
        <div>
          <div>UNIT PRICE</div>
          <span className='text-xs font-medium leading-4 text-[#808080]'>Original Currency</span>
        </div>
      ),
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'exchangeRate',
      accessorFn: (row) => `${row?.chargeCurrency || '-'} 1 = ${row?.invoiceCurrency || '-'} ${formatNumber(row?.exchangeRate) || '-'}`,
      header: (
        <div>
          <div>EXCHANGE RATE</div>
          <span className='text-xs font-medium leading-4 text-[#808080]'>Original Ccy to Invoice Ccy</span>
        </div>
      ),
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'shipmentNumber',
      accessorFn: (row) => row.shipmentNumber || '-',
      header: 'SHIP NO.',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'invoiceNumber',
      accessorFn: (row) => row.invoiceNumber || '-',
      header: 'INVOICES NO.',
      size: 20,
    },
    {
      enableEditing: false,
      enableSorting: true,
      accessorKey: 'date',
      accessorFn: (row) => {
        let date: string;
        switch (dateReference) {
          case 'PICKUP_DATE':
            date = row.pickupDate;
            break;
          case 'DELIVERY_DATE':
            date = row.deliveryDate;
            break;
          case 'INVOICE_DATE':
            date = row.invoiceDate;
            break;
          case 'INVOICE_DUE_DATE':
            date = row.invoiceDueDate;
            break;
          case 'SUBMISSION_DATE':
            date = row.submissionDate;
            break;
          default:
            throw new Error('Invalid date reference');
        }
        return formatDateString(date);
      },
      header: 'DATE',
      Header: (
        <div style={{ textAlign: 'center' }}>
          <div>DATE</div>
          <div style={{ fontSize: '0.75rem', color: '#808080', fontWeight: 'lighter' }}>{beautifyEnum(dateReference)}</div>
        </div>
      ),
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'pol',
      accessorFn: (row) => row.pol || '-',
      header: 'POL',
      size: 20,
    },
    {
      enableEditing: false,
      accessorKey: 'pod',
      accessorFn: (row) => row.pod || '-',
      header: 'POD ',
      size: 20,
    },
    {
      enableEditing: false,
      accessorFn: (row) => (
        <button type='button' onClick={() => window.open(`/action-details/${row.invoiceId}?hideBack=1`)}>
          <img src={EyeIcon} alt='Detail' />
        </button>
      ),
      header: 'DETAILS',
      enableSorting: false,
      accessorKey: 'actions',
      size: 20,
    },
  ].filter(Boolean);

export enum TableTab {
  SHIPMENTS = 'Shipments',
  INVOICES = 'Invoices',
  CHARGES = 'Charges',
  SAVINGS = 'SAVINGS',
  VOLUMETRIC = 'VOLUMETRIC',
}

export const dateColumnOptions = [
  {
    key: 'pickupDate',
    label: 'Pickup Date',
    value: 'PICKUP_DATE',
  },
  {
    key: 'deliveryDate',
    label: 'Delivery Date',
    value: 'DELIVERY_DATE',
  },
  {
    key: 'invoiceDate',
    label: 'Invoice Date',
    value: 'INVOICE_DATE',
  },
  {
    key: 'invoiceDueDate',
    label: 'Invoice Due Date',
    value: 'INVOICE_DUE_DATE',
  },
  {
    key: 'submissionDate',
    label: 'Submission Date',
    value: 'SUBMISSION_DATE',
  },
];
