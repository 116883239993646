import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { NoContractDraftData } from 'src/generated/services/TFinancialApi';

export interface INoRateCardSelectorProps {
  children: ReactNode;
}

export interface INoRateCardSelectorData {
  isShowModal: boolean;
  isHaveDraft: boolean;
  setupDraft(data: NoContractDraftData): void;
  showNoRateCard(): void;
  closeNoRateCard(): void;
}

export const NoRateCardSelectorContext = createContext<INoRateCardSelectorData>(null);

export const NoRateCardSelector = ({ children }: INoRateCardSelectorProps) => {
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isHaveDraft, setIsHaveDraft] = useState<boolean>(false);

  const showNoRateCard = useCallback(() => {
    setIsShowModal(true);
  }, []);

  const closeNoRateCard = useCallback(() => {
    setIsShowModal(false);
  }, []);

  const setupDraft = useCallback((data: NoContractDraftData) => {
    if (data) {
      setIsHaveDraft(true);
    } else {
      setIsHaveDraft(false);
    }
  }, []);

  const value = useMemo(
    () => ({
      isShowModal,
      isHaveDraft,
      setupDraft,
      showNoRateCard,
      closeNoRateCard,
    }),
    [isShowModal, isHaveDraft, setupDraft, showNoRateCard, closeNoRateCard]
  );

  return <NoRateCardSelectorContext.Provider value={value}>{children}</NoRateCardSelectorContext.Provider>;
};

// eslint-disable-next-line react-refresh/only-export-components
export function useNoRateCardSelector() {
  const context = useContext(NoRateCardSelectorContext);

  if (!context) {
    throw new Error(`No Contract selector compound components cannot be rendered outside the No Contract Selector component`);
  }
  return context;
}
