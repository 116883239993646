import { ReactNode, createContext, useMemo, useState } from 'react';

export interface IToastResultSelectorProps {
  children: ReactNode;
}

export interface IRecalculationData {
  shipmentId: string;
  summary?: Record<string, any>;
}

export interface IToastResultSelectorData {
  isShowResult: boolean;
  currentInvoiceId: string;
  invoiceNumber: string;
  shipmentNumber: string;
  workflowStatus: string;
  recalculationData?: IRecalculationData;
  setIsShowResult(value: boolean): void;
  setCurrentInvoiceId(value: string): void;
  setInvoiceNumber(value: string): void;
  setShipmentNumber(value: string): void;
  setWorkflowStatus(value: string): void;
  showToastResult(invoiceId: string, workflow: string, invoiceNum: string, shipmentNum: string, data?: IRecalculationData): void;
  hideToastResult(): void;
}

export const ToastResultSelectorContext = createContext<IToastResultSelectorData>(null);

export const ToastResultSelector = ({ children }: IToastResultSelectorProps) => {
  const [isShowResult, setIsShowResult] = useState<boolean>(false);
  const [currentInvoiceId, setCurrentInvoiceId] = useState<string>(null);
  const [invoiceNumber, setInvoiceNumber] = useState<string>(null);
  const [shipmentNumber, setShipmentNumber] = useState<string>(null);
  const [workflowStatus, setWorkflowStatus] = useState<string>('');
  const [recalculationData, setRecalculationData] = useState<IRecalculationData | null>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showToastResult = (invoiceId: string, workflow: string, invoiceNumber: string, shipmentNumber: string, data?: IRecalculationData) => {
    setIsShowResult(true);
    setCurrentInvoiceId(invoiceId);
    setInvoiceNumber(invoiceNumber);
    setShipmentNumber(shipmentNumber);
    setWorkflowStatus(workflow);
    setRecalculationData(data);
  };

  const hideToastResult = () => {
    setIsShowResult(false);
    setCurrentInvoiceId(null);
    setInvoiceNumber(null);
    setShipmentNumber(null);
    setWorkflowStatus('');
  };

  const value = useMemo(
    () => ({
      isShowResult,
      setIsShowResult,
      currentInvoiceId,
      setCurrentInvoiceId,
      invoiceNumber,
      setInvoiceNumber,
      shipmentNumber,
      setShipmentNumber,
      workflowStatus,
      setWorkflowStatus,
      showToastResult,
      hideToastResult,
      recalculationData,
    }),
    [isShowResult, currentInvoiceId, invoiceNumber, shipmentNumber, workflowStatus, showToastResult, recalculationData]
  );

  return <ToastResultSelectorContext.Provider value={value}>{children}</ToastResultSelectorContext.Provider>;
};
