import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import IconTick from 'src/assets/images/icons/tick-circle-primary.svg';
import IconDanger from 'src/assets/images/icons/danger-alt.svg';
import IconDocAccept from 'src/assets/images/icons/document-accept.svg';
import IconDocWarning from 'src/assets/images/icons/document-warning.svg';
import IconClose from 'src/assets/images/icons/close-rounded.svg';
import { DeviationWorkflowAction } from 'src/infra/types/deviation';
import { beautifyEnum } from 'src/utils/utility';
import { useToastResult } from './useToastResult';

export interface IToastResultProps {}

// Keeping your original constant as requested
const RECALCULATION_STATUSES = ['RECALCULATION_RESOLVED', 'RECALCULATION_PROCUREMENT_REVIEW', 'RECALCULATION_LOGISTIC_REVIEW'];

export const ToastResult: React.FC<IToastResultProps> = () => {
  const { isShowResult, currentInvoiceId, invoiceNumber, shipmentNumber, hideToastResult, workflowStatus, recalculationData } = useToastResult();
  const [isVisible, setIsVisible] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const toastStyles = {
    base: {
      opacity: 0,
      transform: 'translateX(-100%)',
      transition: 'opacity 0.5s ease-out, transform 0.5s ease-out',
    },
    visible: {
      opacity: 1,
      transform: 'translateX(0)',
    },
    exiting: {
      opacity: 0,
      transform: 'translateX(-100%)',
      transition: 'opacity 0.5s ease-in, transform 0.5s ease-in',
    },
  };

  const combinedStyles = {
    ...toastStyles.base,
    ...(isVisible && toastStyles.visible),
    ...(isExiting && toastStyles.exiting),
  };

  const isRecalculation = RECALCULATION_STATUSES.includes(workflowStatus);

  useEffect(() => {
    if (isShowResult && currentInvoiceId) {
      timeoutRef.current = setTimeout(() => {
        setIsVisible(true); // Show the toast after 1 second delay

        // Start the auto-hide timeout
        timeoutRef.current = setTimeout(() => {
          setIsExiting(true); // Trigger exit animation
          setTimeout(() => {
            setIsVisible(false); // Hide the toast after animation
            setIsExiting(false);
            hideToastResult(); // Ensure this happens after the animation
          }, 500); // Match the animation duration (500ms)
        }, 15000); // Toast display duration (15 seconds)
      }, 1000); // Delay before showing the toast (1 second)
    } else {
      // If not visible, clear states and any existing timeouts
      setIsVisible(false);
      setIsExiting(false);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isShowResult, currentInvoiceId, hideToastResult]);

  const handleClose = () => {
    setIsExiting(true); // Trigger exit animation
    setTimeout(() => {
      setIsVisible(false);
      setIsExiting(false);
      hideToastResult();
    }, 500); // Match animation duration
  };

  const getInvoiceIcon = useCallback(() => {
    if (currentInvoiceId) {
      switch (workflowStatus) {
        case DeviationWorkflowAction.REJECT_INVOICE:
          return <img src={IconDanger} alt='' className='w-full' />;
        case 'RECALCULATION_RESOLVED':
          return <img src={IconDocAccept} alt='' className='w-full' />;
        case 'RECALCULATION_LOGISTIC_REVIEW':
        case 'RECALCULATION_PROCUREMENT_REVIEW':
          return <img src={IconDocWarning} alt='' className='w-full' />;
        default:
          return <img src={IconTick} alt='' className='w-full' />;
      }
    }

    return null;
  }, [currentInvoiceId, workflowStatus]);

  const getInvoiceStatus = useCallback(() => {
    let status = 'New Status';

    if (currentInvoiceId) {
      switch (workflowStatus) {
        case DeviationWorkflowAction.READY_FOR_PAYMENT:
        case DeviationWorkflowAction.SEND_TO_OPERATION:
        case 'RECALCULATION_READY_FOR_PAYMENT':
          status = 'Ready for Payment';
          break;
        case DeviationWorkflowAction.REJECT_INVOICE:
          status = 'Rejected';
          break;
        case 'RECALCULATION_RESOLVED':
          status = 'Resolved';
          break;
        case 'RECALCULATION_PROCUREMENT_REVIEW':
          status = 'pending Procurement Review';
          break;
        case 'RECALCULATION_LOGISTIC_REVIEW':
          status = 'pending Logistics Review';
          break;
        default:
          status = 'New Status';
          break;
      }
    }

    return status;
  }, [currentInvoiceId, workflowStatus]);

  if (!isShowResult || !currentInvoiceId) {
    return null;
  }

  return (
    <div className='fixed bottom-48 left-48 z-[99990] flex' style={combinedStyles}>
      <div className='py-14 pl-20 pr-24 rounded-8 bg-[#202020]'>
        <div className='flex gap-12'>
          <div className='w-32'>{getInvoiceIcon()}</div>
          <div className='flex-1'>
            <h3 className='text-neutral-40 font-medium text-14 mb-2'>
              {isRecalculation ? (
                <>
                  The Shipment <strong className='text-white'>No. {shipmentNumber}</strong> from Invoice{' '}
                  <strong className='text-white'>No. {invoiceNumber}</strong> is {getInvoiceStatus()} after recalculations
                </>
              ) : (
                <>
                  Invoice <strong className='text-white'>No. {invoiceNumber}</strong> has been marked as &quot;{getInvoiceStatus()}&quot;
                  {workflowStatus === 'RECALCULATION_READY_FOR_PAYMENT' && ' after recalculations'}
                </>
              )}
            </h3>
            {isRecalculation ? (
              <>
                {recalculationData?.summary && (
                  <div className='mt-8 bg-[#3B3B3B] rounded-full py-4 px-12 inline-flex items-center gap-12'>
                    {Object.entries(recalculationData.summary).map(([key, value]) => (
                      <div
                        key={`${currentInvoiceId}-${key}`}
                        className='text-neutral-50 text-12 flex items-center gap-12 after:w-1 after:h-10 after:bg-[#535353] last:after:hidden'
                      >
                        <span>
                          {value} {beautifyEnum(key)}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <p className='text-12 text-neutral-50 mt-6'>It is now available in Report & Analytics</p>
            )}

            <div className='mt-12'>
              <Link
                to={`/action-details/${currentInvoiceId}`}
                className='text-primary font-medium text-12'
                onClick={() => {
                  hideToastResult();
                }}
              >
                See Invoice
              </Link>
            </div>
          </div>
          <div>
            <button type='button' onClick={handleClose} className='ml-24 w-24 h-24 flex items-center justify-center'>
              <img src={IconClose} alt='Close' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
