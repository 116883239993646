import React, { useMemo } from 'react';
import IconTruck from 'src/presentations/components/organisms/actionDetails/tabsDetails/transportSummary/icons/Truck';
import IconShip from 'src/presentations/components/organisms/actionDetails/tabsDetails/transportSummary/icons/Ship';
import IconAeroplane from 'src/presentations/components/organisms/actionDetails/tabsDetails/transportSummary/icons/Aeroplane';
import IconCourier from 'src/presentations/components/organisms/actionDetails/tabsDetails/transportSummary/icons/Courier';
import IconCt from 'src/presentations/components/organisms/actionDetails/tabsDetails/transportSummary/icons/Ct';
import clsx from 'clsx';

interface TransportMediumProps {
  medium: 'ROAD' | 'SEA' | 'AIR' | 'RAIL' | 'COURIER' | 'CT';
  size?: 'sm' | 'md' | 'lg';
  iconColor?: string;
}

const TransportMediumIcon: React.FC<TransportMediumProps> = ({ medium, size = 'lg', iconColor = '#9F9F9F' }) => {
  let color: string;
  let backgroundColor: string;
  let borderColor: string;

  const sizeIcon = useMemo(() => {
    if (size === 'sm') {
      return 14;
    }
    if (size === 'md') {
      return 16;
    }
    return 20;
  }, [size]);

  switch (medium) {
    case 'ROAD':
      color = '#965812';
      backgroundColor = '#f2ebe3';
      borderColor = '#965812';
      break;
    case 'SEA':
      color = '#30C5FF';
      backgroundColor = '#E6F8FF';
      borderColor = '#30C5FF';
      break;
    case 'AIR':
      color = '#597282';
      backgroundColor = '#EBEEF0';
      borderColor = '#597282';
      break;
    case 'RAIL':
      color = '#F79824';
      backgroundColor = '#FEF3E5';
      borderColor = '#F79824';
      break;
    case 'CT':
      color = '#43973C';
      backgroundColor = '#E8F3E8';
      borderColor = '#43973C';
      break;
    case 'COURIER':
      color = '#36454F';
      backgroundColor = '#F5F6FA';
      borderColor = '#36454F';
      break;
    default:
      color = iconColor;
      backgroundColor = '#F5F6FA';
      borderColor = 'transparent';
      break;
  }

  let icon = null;

  switch (medium) {
    case 'ROAD':
    case 'RAIL':
      icon = <IconTruck color={color} size={sizeIcon} />;
      break;
    case 'SEA':
      icon = <IconShip color={color} size={sizeIcon} />;
      break;
    case 'AIR':
      icon = <IconAeroplane color={color} size={sizeIcon} />;
      break;
    case 'COURIER':
      icon = <IconCourier color={color} size={sizeIcon} />;
      break;
    case 'CT':
      icon = <IconCt color={color} size={sizeIcon} />;
      break;
    default:
      icon = null;
      break;
  }

  return (
    <div
      className={clsx(
        'rounded-full flex items-center justify-center',
        size === 'sm' && 'w-20 h-20',
        size === 'md' && 'w-24 h-24',
        size === 'lg' && 'w-36 h-36'
      )}
      style={{ backgroundColor, border: `1px solid ${borderColor}` }}
    >
      {icon}
    </div>
  );
};

export default TransportMediumIcon;
